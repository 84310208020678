import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogRef, DynamicDialogModule, DynamicDialogConfig } from 'primeng/dynamicdialog';


import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarModule } from 'primeng/sidebar';
import { RippleModule } from 'primeng/ripple';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { SearchInputComponent } from '../form/search-input/search-input.component';
import { Platform } from '@angular/cdk/platform';
import { Observable, Subscription, map, take, tap } from 'rxjs';
import { BootstrapGridBreakpoints, BreakpointsService } from '../../services/breakpoints.service';
import { UserInnerMenuComponent } from '../user-inner-menu/user-inner-menu.component';
import { UserImageBlockComponent } from '../user-image-block/user-image-block.component';
import { MenuModule } from 'primeng/menu';
import {  IAuthMe, IProfile } from '../../models/auth/interfaces/auth.interfaces';
import { AuthService } from '../../models/auth/auth.service';
import { SkeletonModule } from 'primeng/skeleton';
import { StateService } from '../../services/state.service';
import { MenuItem } from 'primeng/api';
import { OrderState } from '../../api/orders/orders.interface';
import { BadgeModule } from 'primeng/badge';
import { getMessaging, Messaging, onMessage } from '@angular/fire/messaging';
import { environment } from '../../../../environments/environment';
import { getToken } from '@firebase/messaging';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ToolbarModule,
    InputTextModule,
    SidebarModule,
    RippleModule,
    DynamicDialogModule,
    RouterLink,
    SearchInputComponent,
    UserInnerMenuComponent,
    UserImageBlockComponent,
    MenuModule,
    SkeletonModule,
    BadgeModule
],
    providers: [
      DialogService, DynamicDialogRef, DynamicDialogConfig
    ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent implements OnInit, OnDestroy {
  public userProfile$!: Observable<IProfile>;

  public selectedVehicle: boolean = false;
  public searchControl: FormControl = new FormControl('');
  private previousScroll = 0;
  public navHidden = false;
  public navbarOpen = false;
  public stickyNav = false;
  public userMobileMenuVisible: boolean = false;
  @HostListener("window:scroll", [])
  onScroll() {
    if (typeof window !== "undefined") {
      if (this.previousScroll < window.scrollY) {
        if (window.scrollY > 100) {
          if (!this.navbarOpen) {
            this.navHidden = true;
            this.stickyNav = false;
          } else {
            this.navHidden = false;
            this.stickyNav = true;
          }
        }
      } else {
        if (window.scrollY < 100) {
          this.navHidden = false;
          this.stickyNav = false;
        } else {
          this.stickyNav = true;
          this.navHidden = false;
        }
      }

    }
    this.previousScroll = window.scrollY;
  }


  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.searchControl.value && this.searchControl.value !== '') {
      this.searchProducts(this.searchControl.value);
    }
  }
  public mobileMenuIsOpened: boolean = false;
  public addVehicleBottomSheetOpened: boolean = false;
  private loginDialogRef: DynamicDialogRef | undefined;
  private addVehicleDialogRef: DynamicDialogRef | undefined;
  private lgSubscription: Subscription = new Subscription;
  public lgLayout: boolean = true;

  @Output() toggleSidebar: EventEmitter<any> = new EventEmitter<any>(); 



  public userMenu: MenuItem[] | undefined = [
    
    {
      
      label: 'User menu',
      items: [
          {
              label: 'Account',
              icon: 'pi pi-user-edit',
              command: () => {
                 this.router.navigate(['/admin/account/summary'])
              }
          },
          {
              label: 'Log out',
              icon: 'pi pi-sign-out',
              command: () => {
                  this.logOut();
              }
          }
      ]
  },
  ]

  constructor(public dialogService: DialogService, 
    private platform: Platform, private breakpointsService: BreakpointsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private stateService: StateService,
    private router: Router,
    private fcmMessaging: Messaging){

  }


  ngOnInit(): void {
    this.getActiveUser();
    if(this.platform.isBrowser){
      this.lgSubscription = this.breakpointsService.isBreakpointOrLower(BootstrapGridBreakpoints.LGLabel).subscribe({
        next: (matches: boolean) => {
            this.lgLayout = matches;
        }
      } 
      )
    }
  }

  
  requestPermission() {
    const messaging = getMessaging()
    getToken(messaging, {
      vapidKey: environment.vapidKey
    }).then(
      (currentToken) => {
        if (currentToken) {
          console.log('Your token is: ', currentToken);
          const pushTokens = this.authService?.authMe?.profile?.pushTokens || [];
          if (!pushTokens.some(tokenObj => tokenObj.token === currentToken)) {
            this.authService.pushToken(currentToken).pipe(take(1)).subscribe(() => {
              console.log('Token has been pushed')
            })
          } else {
            console.log('Push token already exists')
          }

          
        } else {
          if (Notification.permission === 'denied') {
            alert('Notifications are blocked. Please enable them in browser settings.');
          } else {
            Notification.requestPermission().then(permission => {
              if (permission === 'granted') {
                // Retry token retrieval
                this.requestPermission();
              } else {
                console.log('Notification permission denied.');
              }
            });
          }
          
        }
      }
    ).catch((error) => {
      console.log('An error occurred while retrieving token. ', error.message);
    })
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);

    })
  }


  private logOut(){
    this.authService.logout();
  }

  public toggleSidebarEvent(){
    this.toggleSidebar.emit();
  }

  private getActiveUser(){
    this.userProfile$ = this.authService.getCurrentUser().pipe(
      take(1),
      tap((data: IAuthMe) => {
        if(data.profile){
          this.stateService.setState('signedIn', true)
        }
        this.authService.authMe = data;
      }),
     map((data) => data.profile as IProfile));
  }

  public searchProducts(searchTerm: string){
    this.router.navigate(['/admin/orders'], {
      queryParams: { search: searchTerm, state: OrderState.All },
      relativeTo: this.route,
      queryParamsHandling: 'merge',
  });
  this.searchControl.setValue('', { emitEvent: false });
  }

  public goToUserPage(){
    this.router.navigate(['/user/account']);
  }




  public showAddVehicleBottomSheet(){
    this.addVehicleBottomSheetOpened = true;
  }

  public closeBottomSheet(event:any){
    if(event){
      this.selectedVehicle = true;
      
    } else {
      this.selectedVehicle = false;
    }
    this.addVehicleBottomSheetOpened = false;
  }

  public addSelectedVehicle(event: any){
    if(event){
      this.selectedVehicle = true;
    } else {
      this.selectedVehicle = false;
    }
  }

  ngOnDestroy() {
    if (this.loginDialogRef) {
      this.loginDialogRef.destroy();
  }
    if (this.addVehicleDialogRef) {
        this.addVehicleDialogRef.destroy();
    }
    if(this.lgSubscription){
      this.lgSubscription.unsubscribe();
    }
}
}

